<template>
    <!--<div>
         <VueSlickCarousel
                :arrows="false"
                :dots="true"
                :touchMove="true"
                :infinite="true"
                :speed="500"
                :autoplay="true"
                :autoplaySpeed="5000"
                :pauseOnHover="true">
            <div v-for="slide in slides"
                    class='slide'
                    :key="slide.id">
                <img  :src="getImgUrl(slide.filename)" v-bind:alt="slide.id" class="fitImage">
            </div>
        </VueSlickCarousel> 
        
    </div>-->
    <div>
        <carousel :items-to-show="1"
            :wrapAround="true"
            :transition="500"
            :autoplay="5000">
            <slide v-for="slide in slides" :key="slide">
                <img :src="getImgUrl(slide.filename)" v-bind:alt="slide.id" class="fitImage">
            </slide>
            <template #addons>
                <!-- <navigation /> -->
                <pagination />
            </template>
        </carousel>
    </div>
</template>

<script>
    // import VueSlickCarousel from 'vue-slick-carousel'
    // import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination } from 'vue3-carousel';
 
    export default {
        name: "MySlider",
        components: { 
            // VueSlickCarousel,
            Carousel,
            Slide,
            Pagination,
            // Navigation 
        },
        data () {
            return {
                slides: [
                    {
                        id: 0,
                        filename: '0-1.jpg'
                    },
                    {
                        id: 1,
                        filename: '1.jpg'
                    },
                    {
                        id: 2,
                        filename: '2.jpg'
                    },
                    {
                        id: 3,
                        filename: '3.jpg'
                    },
                    {
                        id: 4,
                        filename: '4.jpg'
                    },
                    {
                        id: 5,
                        filename: '5.jpg'
                    },
                    {
                        id: 7,
                        filename: '7.jpg'
                    },
                    {
                        id: 8,
                        filename: '8.jpg'
                    }
                ]
            }
        },
        methods: {
            next () {
                const first = this.slides.shift()
                this.slides = this.slides.concat(first)
            },
            previous () {
                const last = this.slides.pop()
                this.slides = [last].concat(this.slides)
            },
            getImgUrl(fileName) {
                return require('../assets/slider/' + fileName)
            }
        }
    }
</script>

<style lang="scss">
    .fitImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom left;
    }

    .slick-slider {
        touch-action: auto;
        -ms-touch-action: auto;
    }

    .slick-list {
        .slick-track {
            .slide {
                height: 100vh;
                outline: none !important;
            }
        }
    }

    .carousel {
        .carousel__slide {
            height: 100vh;
        }
    }

    .carousel__pagination {
        position: absolute;
        top: 0;
        list-style: none;
        display: flex;
        flex-direction: row;

        li {
            float: left;
            margin-right: 10px;
            opacity: 0.7;

            button.carousel__pagination-button {
                font-size: 0;
                color: transparent;
                background-color: rgba(255,255,255,0.8);
                outline: none;
                border: 0;
                border-radius: 0;
                width: 16px;
                height: 7px;
                display: inline-block;
                cursor: pointer;

                &.carousel__pagination-button--active {
                    background-color: #ed1c24;
                }
            }
        }
    }

    @media (max-width: 992px) {
        .fitImage {
            height: auto;
        }
        .slick-list {
            .slick-track {
                .slide {
                    height: auto;
                }
            }
        }
        .slick-dots {
            padding: 0;
            margin: 0 12px;
        }

        .carousel {
            .carousel__slide {
                height: auto;
            }
        }

        .carousel__pagination {
            padding: 0 0 0 4px;
            margin: 0;
        }
    }

    @media (prefers-color-scheme: dark) {
        .slider {
            background: #222222;
            color: white;

            img.fitImage {
                opacity: 0.89;
            }
        }
    }
</style>