<template>
  <div id="app">
    <calendar class="calendar"/>
    <player class="player"/>
    <div class="mainBlock">
      <slider class="slider"/>
      <schedule></schedule>
      <spotlight></spotlight>
    </div>
  </div>
</template>

<script>
import Player from "@/components/player";
import Calendar from "@/components/calendar";
import Slider from "@/components/slider";
import Spotlight from "@/components/spotlight";
import Schedule from "@/components/schedule";

export default {
  name: 'app',
  components: {
    Schedule,
    Spotlight,
    Calendar,
    Player,
    Slider
  }
}
</script>

<style lang="scss">
  html,
  body,
  #app {
    margin: 0;
    padding: 0;
    font-family: 'Nunito Sans', Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    width: 100%;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
  }

  * {
    &:before,
    &:after {
      box-sizing: border-box;
    }
  }

  html {
    --scrollbarBG: #CFD8DC;
    --thumbBG: #5352ed;
  }
  body::-webkit-scrollbar {
    width: 16px;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
  }


  .mainBlock {
    color: #2c3e50;
    height: 100%;
    width: calc( 100% - 320px);
    padding-left: 320px;
    /*background-image: url("assets/pattern.png");*/
    /*background-repeat: repeat;*/
    background-color: #f1f2f6;
  }

  .player {
    z-index: 16;
  }

  .calendar {
    position: fixed;
    left: 0;
    width: 320px;
    height: 100%;
    background: #f1f2f6;
    text-align: center;

    padding: 20px 0;
    -webkit-box-shadow: 16px 0 20px -20px rgba(46,46,46,0.55);
    -moz-box-shadow: 16px 0 20px -20px rgba(46,46,46,0.55);
    box-shadow: 16px 0 20px -20px rgba(46,46,46,0.55);
    z-index: 11;
  }

  .slider {
    overflow: hidden;
  }

  .explode {
    animation: explosion 5s linear forwards .2s;

  }

  @keyframes explosion {
    0% {
      -webkit-filter: saturate(1);
      filter: saturate(1);
    }
    30% {
      -webkit-filter: saturate(2);
      filter: saturate(2);
    }
    70% {
      -webkit-filter: saturate(4);
      filter: saturate(4);
    }
    99% {
      -webkit-filter: saturate(8);
      filter: saturate(8);
    }
    100% {
      filter: opacity(0);
    }
  }

  @media (max-width: 992px) {
    .calendar {
      position: relative;
      width: 100%;
    }

    .mainBlock {
      width: 100%;
      padding-left: 0;
      height: auto;
    }

    .player-bar {
      width: 100%;
    }
  }

  @media (prefers-color-scheme: dark) {
    .mainBlock {
      background: #1f2634;
      color: white;
    }
  }

</style>
