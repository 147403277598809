<template>
  <div>
    <div class="calendarHead">
      <div class="logoContainer">
        <img class="logo" alt="Modyan Logo" src="../assets/modyan_logo.png" srcset="../assets/modyan_logo@2x.png 2x">
        <h1 class="slogan">
          <span>RADYO</span>
          <span>MODYAN</span>
        </h1>
      </div>
<!--      <div class="switchContainer">-->
<!--        <label class="switch">-->
<!--          <input type="checkbox" v-model="lightSwitch" v-on:change="applySetting()">-->
<!--          <span></span>-->
<!--        </label>-->
<!--      </div>-->
    </div>
    <div class="calendarBody">
      <div v-if="currentEvent">
        <h4>
          Canlı
        </h4>
        <ul class="calendarList currentList">
          <li>
            <h3>
              {{ currentEvent.summary }}
            </h3>
            <span>
              {{ formatTime(currentEvent.start.dateTime) }} - {{ formatTime(currentEvent.end.dateTime) }}
            </span>
          </li>
        </ul>
      </div>
      <div v-if="upcomingEvents.length > 0">
        <h4>
          Sıradakiler
        </h4>
        <ul class="calendarList upcomingList">
          <!--{{events}}-->
          <li v-for="event in upcomingEvents" :key="event.summary">
            <h3>
              {{ event.summary }}
            </h3>
            <span>
              {{ formatTime(event.start.dateTime) }} - {{ formatTime(event.end.dateTime) }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    </div>
</template>

<script>
import * as axios from "axios";

export default {
  name: 'MyCalendar',
  data() {
    return {
      upcomingEvents: [],
      currentEvent: null,
      isCurrentEvent: Boolean,
      events: {},
      lightSwitch: true,
      STORAGE_KEY: 'user-color-scheme',
      COLOR_MODE_KEY: '--color-mode'
    }
  },
  methods: {
    googleCalendarAPI: function () {
      const now = new Date();
      axios
        .get('https://www.googleapis.com/calendar/v3/calendars/radyomodyan%40gmail.com/events?maxResults=3&orderBy=startTime&singleEvents=true&timeMin='
                + now.toISOString()
                +'&key=AIzaSyC2ct9cjodx78XLFBsCYBL1osogqgyEwBg')
        .then(response => (this.events = response.data))
        .then(() => {
          this.upcomingEvents = [];
          this.isCurrentEvent = false;
          if(this.events.items.length > 0) {
            this.events.items.forEach((value) => {
              const eventStart = new Date(value.start.dateTime);
              const eventEnd = new Date(value.end.dateTime);
              if(this.getColor(value.description)) {
                value.type = this.getColor(value.description);
              }
              if(eventStart < now && eventEnd > now) {
                this.isCurrentEvent = true;
                this.currentEvent = value;
              } else {
                this.upcomingEvents.push(value);
              }
            });
            if(!this.isCurrentEvent) {
              this.currentEvent = null;
            }
            if(this.currentEvent) {
              this.upcomingEvents = this.upcomingEvents.slice(0);
            }
          } else {
            this.currentEvent = null;
            this.upcomingEvents = [];
          }
        })
    },
    intervalFetchData: function () {
      setInterval(() => {
        this.googleCalendarAPI();
      }, 300000);
    },
    getColor: function (value) {
      if(value) {
        return value.split(',')[0];
      } else {
        return null;
      }
    },
    getSouncloudLink: function (value) {
      return value.split(',')[1];
    },

    applySetting: function() {
      if(this.lightSwitch) {
        document.documentElement.setAttribute('data-theme', 'light');
        localStorage.setItem(this.STORAGE_KEY, this.lightSwitch);
      } else {
        document.documentElement.setAttribute('data-theme', 'dark');
        localStorage.setItem(this.STORAGE_KEY, this.lightSwitch);
      }
    },
    toggleSetting: function() {
      let currentSetting = localStorage.getItem(this.STORAGE_KEY);

      switch(currentSetting) {
        case null:
          currentSetting = this.getCSSCustomProp(this.COLOR_MODE_KEY) === 'dark' ? 'light' : 'dark';
          break;
        case 'light':
          currentSetting = 'dark';
          break;
        case 'dark':
          currentSetting = 'light';
          break;
      }
      localStorage.setItem(this.STORAGE_KEY, currentSetting);
      return currentSetting;
    },
    formatTime(value) {
      const startDate = new Date(value);
      return startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0')
    }
  },
  mounted () {
    if(localStorage.getItem(this.STORAGE_KEY)){
      this.lightSwitch = localStorage.getItem(this.STORAGE_KEY);
      this.applySetting();
    }

    this.googleCalendarAPI();
    this.intervalFetchData();
  },
  computed: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  $modyanRed: #ed1c24;

  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .calendarHead {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .logoContainer {
      display: flex;
      flex-direction: row;
      align-content: flex-end;
    }

    .slogan {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: $modyanRed;
      margin: 7px 0 0 0;
      line-height: 26px;

      span:first-child {
        font-weight: 300;
      }
    }

    .logo {
      max-width: 50px;
      height: 56px;
      margin: 0 10px;
    }
  }

  .calendarBody {
    h4 {
      font-weight: 300;
      font-size: 24px;
      margin: 0;
      text-align: left;
      padding: 0 20px;
    }
  }

  ul.currentList {
    li {
      &:nth-child(1) {
        background-position: 0;
      }
    }
  }

  ul.upcomingList {
    li {
      &:nth-child(1) {
        background-position: 120px;
      }

      &:nth-child(2) {
        background-position: 240px;
      }
    }
  }

  ul.calendarList {
    li {
      width: 320px;
      background: #5352ed;
      -webkit-box-shadow: 0px 0px 30px -12px rgba(83,82,237,1);
      -moz-box-shadow: 0px 0px 30px -12px rgba(83,82,237,1);
      box-shadow: 0px 0px 30px -12px rgba(83,82,237,1);
      color: white;
      padding: 16px 32px;
      text-align: left;
      box-sizing: border-box;
      margin: 10px;

      background-image: url("../assets/pattern.png");
      background-repeat: repeat;

      /* Calendar indicators
        Live : 1 #C70039
        Podcast : 2 #DAF7A6
        Video : 3 #FFC300
      */

      &.live {
        background: rgba(199, 0, 57, 1);
        -webkit-box-shadow: 0px 0px 30px -12px rgba(199, 0, 57,1);
        -moz-box-shadow: 0px 0px 30px -12px rgba(199, 0, 57,1);
        box-shadow: 0px 0px 30px -12px rgba(199, 0, 57,1);
      }

      &.podcast {
        background: rgba(218, 247, 166, 1);
        -webkit-box-shadow: 0px 0px 30px -12px rgba(218, 247, 166,1);
        -moz-box-shadow: 0px 0px 30px -12px rgba(218, 247, 166,1);
        box-shadow: 0px 0px 30px -12px rgba(218, 247, 166,1);
      }

      &.video {
        background: rgba(255, 195, 0, 1);
        -webkit-box-shadow: 0px 0px 30px -12px rgba(255, 195, 0,1);
        -moz-box-shadow: 0px 0px 30px -12px rgba(255, 195, 0,1);
        box-shadow: 0px 0px 30px -12px rgba(255, 195, 0,1);
      }

      h3 {
        margin: 0;
      }
    }
  }

  /* Switch starts here */
  $red: #ed1c24;
  $green: #48EA8B;

  .switchContainer {
    margin: 0 10px;
  }

  .switch {
    cursor: pointer;
    input {
      display: none;
      & + span {
        width: 48px;
        height: 28px;
        border-radius: 14px;
        transition: all .3s ease;
        display: block;
        position: relative;
        background: $red;
        box-shadow: 0 8px 16px -1px rgba($red, .2);
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          transition: all .3s ease;
        }
        &:before {
          top: 5px;
          left: 5px;
          width: 18px;
          height: 18px;
          border-radius: 9px;
          border: 5px solid #fff;
        }
        &:after {
          top: 5px;
          left: 32px;
          width: 6px;
          height: 18px;
          border-radius: 40%;
          transform-origin: 50% 50%;
          background: #fff;
          opacity: 0;
        }
        &:active {
          transform: scale(.92);
        }
      }
      &:checked {
        & + span {
          background: $green;
          box-shadow: 0 8px 16px -1px rgba($green, .2);
          &:before {
            width: 0px;
            border-radius: 3px;
            margin-left: 27px;
            border-width: 3px;
            background: #fff;
          }
          &:after {
            animation: blobChecked .35s linear forwards .2s;
          }
        }
      }
      &:not(:checked) {
        & + span {
          &:before {
            animation: blob .85s linear forwards .2s;
          }
        }
      }
    }
  }

  @keyframes blob {
    0%, 100% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.12, .94);
    }
    60% {
      transform: scale(.96, 1.06);
    }
  }

  @keyframes blobChecked {
    0% {
      opacity: 1;
      transform: scaleX(1);
    }
    30% {
      transform: scaleX(1.44);
    }
    70% {
      transform: scaleX(1.18);
    }
    50%,
    99% {
      transform: scaleX(1);
      opacity: 1;
    }
    100% {
      transform: scaleX(1);
      opacity: 0;
    }
  }

  @media (max-width: 992px) {
  }

  @media (max-width: 768px) {
    .calendar {
      padding: 10px 0;
      text-align: left;
    }

    .calendarHead {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;

      .logo {
        max-width: 50px;
        margin: 0 10px;
      }
    }

    .calendarBody {
      h4 {
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        font-size: 20px;
      }
    }

    ul.calendarList {
      margin-top: 0;
      margin-bottom: 10px;

      li{
        max-width: 400px;
        width: 100%;
        margin: 4px 0;
        padding: 8px 20px;
        text-align: left;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 576px) {

  }

  @media (prefers-color-scheme: dark) {
    .calendar {
      background: #2d3436;
      color: white;
    }

    ul.calendarList {

      li{
        background: #5555b6;
        background-image: url("../assets/pattern.png");
        background-repeat: repeat;
      }
    }
  }

</style>
