//import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import 'vue-awesome/icons/play'
import 'vue-awesome/icons/stop'
import moment from 'moment';
// import Icon from 'vue-awesome/components/Icon'
// import VueGtag from "vue-gtag";

// Vue.config.productionTip = false
// Vue.component('v-icon', Icon)

// Vue.use(VueGtag, {
//   config: { id: "UA-162281722-1" }
// });

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
// createApp(App).mount('#app')

let app=createApp(App);
app.config.globalProperties.$moment=moment;
app.mount('#app');