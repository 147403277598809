<template>
    <div class="schedule">
        <h4>
            YAYIN AKIŞI
        </h4>
        <div v-if="!apiSuccess">
            Yayın akışını şuan gösteremiyoruz :(
        </div>
        <div class="weekContainer" v-if="apiSuccess">
            <ul class="dayChooser">
                <li v-for="(daily, index) in thisWeek" :key="index" v-on:click="currentDay = index" v-bind:class="{ active: currentDay === index }">
                    {{ getTrDayName(index) }}
                </li>
            </ul>
            <div v-for="(daily, index) in thisWeek" :key="index" class="scheduleListContainer">
                <div v-if="currentDay === index">
                    <ul class="scheduleList">
                        <li v-for="event in daily" :key="event.summary">
                            <h3>
                                {{ event.summary }}
                            </h3>
                            <span>
                          {{ formatTime(event.start.dateTime) }} - {{ formatTime(event.end.dateTime) }}
                        </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as axios from "axios";

    export default {
        name: "MySchedule",
        data () {
            return {
                events: {},
                startOfSchedule: {},
                endOfWeek: {},
                endOfSchedule: {},
                thisWeek: [],
                nextWeek: [],
                currentIndexes: [],
                currentDay: Number,
                apiSuccess: Boolean
            }
        },
        mounted () {
            const now = new Date().toUTCString();
            this.currentDay = (parseInt(this.$moment(now).utc(true).add(3, 'hour').day())+6)%7;
            this.currentIndexes = [0,0];
            this.startOfSchedule = this.$moment(now).utc(true).startOf('week').add(1, 'day').subtract(1, 'hour');
            this.endOfWeek = this.$moment(now).utc(true).startOf('week').add(1, 'week').add(1, 'day');
            this.endOfSchedule = this.$moment(now).utc(true).startOf('week').add(2, 'week').add(1, 'day').subtract(1, 'hour');
            this.googleCalendarAPI();
        },
        methods: {
            googleCalendarAPI: function () {
                axios
                    .get('https://www.googleapis.com/calendar/v3/calendars/radyomodyan%40gmail.com/events?orderBy=startTime&singleEvents=true&timeMin='
                        + this.startOfSchedule.toISOString()
                        + '&timeMax='
                        + this.endOfSchedule.toISOString()
                        +'&key=AIzaSyC2ct9cjodx78XLFBsCYBL1osogqgyEwBg')
                    .then(response => (this.events = response.data))
                    .then(() => {
                        this.apiSuccess = true;
                        this.thisWeek = [[],[],[],[],[],[],[]];
                        this.nextWeek = [[],[],[],[],[],[],[]];
                        this.events.items.forEach((current) => {
                            this.distributeEvents(current)
                        });
                    })
                    .catch( () => (this.apiSuccess = false));
            },
            distributeEvents: function (current) {
                const currentDay = (parseInt(this.$moment(current.start.dateTime).format('d'))+6)%7;

                if(currentDay === this.currentIndexes[1]) {
                    if(this.currentIndexes[0] === 0) {
                        this.thisWeek[this.currentIndexes[1]].push(current);
                    } else {
                        this.nextWeek[this.currentIndexes[1]].push(current);
                    }
                } else {
                    this.currentIndexes[1] = this.currentIndexes[1]+1;
                    if(this.currentIndexes[1] === 7) {
                        this.currentIndexes = [1,0]
                    }
                    this.distributeEvents(current)
                }
            },
            formatTime(value) {
                const startDate = new Date(value);
                return startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0')
            },
            getTrDayName(value) {
                switch (value) {
                    case 0:
                        return 'Pzt'
                    case 1:
                        return 'Sal'
                    case 2:
                        return 'Çar'
                    case 3:
                        return 'Per'
                    case 4:
                        return 'Cum'
                    case 5:
                        return 'Cmt'
                    case 6:
                        return 'Paz'
                    default:
                        return '???'
                }
            }
        },
        computed: {
            
        }
    }
</script>

<style lang="scss">
    .schedule {
        padding: 10px 30px 30px 40px;

        h4 {
            font-size: 48px;
            margin: 10px 0;
        }
    }

    ul.scheduleList {
        list-style: none;
        margin: 20px 0 0 0;
        padding: 0;
        li {
            width: 600px;
            background: #2d3436;
            -webkit-box-shadow: 0px 0px 30px -12px rgba(162, 155, 254, 1);
            -moz-box-shadow: 0px 0px 30px -12px rgba(162, 155, 254, 1);
            box-shadow: 0px 0px 30px -12px rgba(162, 155, 254, 1);
            color: white;
            padding: 6px 20px;
            text-align: left;
            box-sizing: border-box;
            margin: 6px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;


            /* Calendar indicators
              Live : 1 #C70039
              Podcast : 2 #DAF7A6
              Video : 3 #FFC300
            */

            &.live {
                background: rgba(199, 0, 57, 1);
                -webkit-box-shadow: 0px 0px 30px -12px rgba(199, 0, 57,1);
                -moz-box-shadow: 0px 0px 30px -12px rgba(199, 0, 57,1);
                box-shadow: 0px 0px 30px -12px rgba(199, 0, 57,1);
            }

            &.podcast {
                background: rgba(218, 247, 166, 1);
                -webkit-box-shadow: 0px 0px 30px -12px rgba(218, 247, 166,1);
                -moz-box-shadow: 0px 0px 30px -12px rgba(218, 247, 166,1);
                box-shadow: 0px 0px 30px -12px rgba(218, 247, 166,1);
                color: black;
            }

            &.video {
                background: rgba(255, 195, 0, 1);
                -webkit-box-shadow: 0px 0px 30px -12px rgba(255, 195, 0,1);
                -moz-box-shadow: 0px 0px 30px -12px rgba(255, 195, 0,1);
                box-shadow: 0px 0px 30px -12px rgba(255, 195, 0,1);
            }

            h3 {
                margin: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                width: 80%;
                overflow: hidden;
            }
        }
    }

    .weekContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-height: 600px;
    }

    .dayChooser {
        list-style: none;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        width: 600px;
        justify-content: space-between;

        li {
            margin: 0;
            padding: 10px 17px;
            background: transparent;
            color: #2d3436;
            font-weight: 600;
            cursor: pointer;
            border: 2px solid #2d3436;

            &.active {
                background: #2d3436;
                color: white;
            }
        }
    }

    @media (max-width: 992px) {
        .scheduleListContainer {
            width: 100%;
        }

        .weekContainer {
            width: 100%;
            display: inline-flex;
            min-height: inherit;
        }

        ul.scheduleList {
            li {
                width: 100%;
                padding: 6px 10px;

                h3 {
                    width: 60%;
                    font-size: 13px;
                }
            }
        }

        .dayChooser {
            width: 100%;

            li {
                padding: 10px 4px;
            }
        }

        .schedule {
            padding: 0 10px 20px 10px;

            h4 {
                font-size: 24px;
            }
        }
    }

    @media (prefers-color-scheme: dark) {
        .schedule {
            background: #1f2634;
            color: white;
        }

        .dayChooser {
            li {
                color: white;
                border: 2px solid white;

                &.active {
                    color: black;
                    background: white;
                }
            }
        }

        ul.scheduleList {
            li {
                background: #5555b6;
            }
        }
    }
</style>
