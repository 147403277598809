<template>
    <div class="spotlight">
        <!--<h4>
            <span>
                <span v-if="currentLang === 'tr'">
                    AÇIK ÇAĞRI
                </span>
                <span v-if="currentLang === 'en'">
                    OPEN CALL
                </span>
            </span>
            <div class="langChooser">
                <span v-on:click="currentLang = 'tr'" v-bind:class="{ active: currentLang === 'tr' }">
                    TR
                </span>
                <span v-on:click="currentLang = 'en'" v-bind:class="{ active: currentLang === 'en' }">
                    EN
                </span>
            </div>
        </h4>
        <div v-if="currentLang === 'tr'">
            <p>
                İçinde bulunduğumuz zamanlar, aklımıza önemli birkaç soru getiriyor:
                İnternet üzerinden işleyen bir radyo tam olarak nedir veya ne yapar? Bir radyo programı veya radyo için bir çalışma nedir? En önemlisi de, nasıl bir arada olabiliriz?
            </p>
            <p>
                Yakın gelecekte yapmayı planladığımız bir açık çağrıyı, iletişim kanallarımızın kısıtlandığı bir zamanda öne çekmek istiyor, sizleri, bu aynı zamanda Geçici Otonom Bölge olma potansiyeli de taşıyan alanı keşfetmemize yardımcı olmaya, radyo için çalışmalar göndermeye davet ediyoruz.
            </p>
            <p>
                Gönderileriniz ses işleri, radyo tiyatroları, alan kayıtları, sevdiklerimize mektuplar ve benzeri birçok şey içerebilir, bunlarla sınırlı değildir. Özgün, korkusuz ve özgür olmaktan çekinmeyiniz (: Şu anda birbirimizi hissetmeye ihtiyacımız var.
            </p>
            <p>
                Başvurular bütün yaşlara, cinsiyetlere/kuirlere/non-binary’lere, uluslara ve tecrübe seviyelerindeki bireylere açıktır.
            </p>
            <h5>
                Biz kimiz?
            </h5>
            <p>
                Radyo Modyan, kaynak ve dinleyici arasındaki tümsekli bir internet yolculuğu. Her hafta çoğu canlı 30’dan çok yayını olan radyomuz, sanatın paylaşımını mümkün kılan kanallarda alternatif modeller geliştiriyor. Pazar ekonomisinden ve güncel siyasi engellerden sıyrılmış; müziğin, müzisyenin ve sanat etrafında birleşen seyircinin merkeze alındığı, herhangi bir kurumsal ya da ekonomik faydanın barınmadığı bir çatıyı ayakta tutmaya çalışıyoruz.
            </p>
            <p>
                Gönderiler, incelenmelerini takip eden hafta, Pazartesi - Pazar 12:00-14:00 saatleri arasında modyan.com adresinde çalınacaktır. Süreç ilerledikçe programın detaylarını biz de keşfediyor ve paylaşıyor olacağız, anlayışınız için şimdiden teşekkür ederiz.
            </p>
            <h5>
                Başvuru Detayları:
            </h5>
            <p>
                Son Başvuru Tarihi: Her Cumartesi günü gelen başvurular incelenecektir.
            </p>
            <p>
                Çalışmalarınızın uzunluğu: 1 ila 15 dakika arasında.
            </p>
            <h5>
                Başvuru materyalleri:
            </h5>
            <ul>
                <li>Ses dosyanız (wav veya mp3)</li>
                <li>Künye (Başlık, eser sahibi, yıl) </li>
                <li>Açıklama: En fazla 150 kelime</li>
            </ul>
            <p>
                Başvurularınız tek bir dosya halinde Wetransfer ile radyomodyan@gmail.com adresine gönderilmelidir.
            </p>
            <p>
                İnternette oluşturduğumuz bölgenin hepimizin kendini özgürce ifade ettiği bir alan olarak kalmasını sağlamak adına, ifade üslubumuzda yapıcı olmayı ve aşağılayıcı olmamayı öneririz.
            </p>
            <p>
                Çalışmalarınızı bizimle paylaşarak, Radyo Modyan tarafından, belirtilecek süre boyunca www.modyan.com adresinde çalınmalarına ve sosyal medya hesaplarından erişilir olmalarına izin veriyorsunuz. Bütün haklar sanatçılara aittir ve öyle kalacaktır, her zaman!
                Herhangi bir sorunuz doğrultusunda, email adresimizden bizimle iletişime geçmekten çekinmeyiniz.
            </p>
        </div>
        <div v-if="currentLang === 'en'">
            <p>
                Times like this bring some important questions to mind:
                What does a radio that functions through the internet mean or really do? What is a radio program or a work for radio? Most importantly, how can we connect?
            </p>
            <p>
                We would like to bring forward an open call we were planning for the near future, following the limitation of channels of communication, and invite you to help us explore this, what has the potential to also be a Temporary Autonomous Zone, by submitting works for radio.
            </p>
            <p>
                Submissions can include but are not limited to sound pieces, radio plays, letters to loved ones, field recordings et cetera. Feel free to feel creative, courageous and free (: We need to feel each other's presences right now.
            </p>
            <p>
                Submissions are open to all ages, genders/queers/non-binaries, nationalities, and levels of experience.
            </p>
            <h5>
                Who are we?
            </h5>
            <p>
                Radyo Modyan is a bumpy internet ride between the source and the listener. Our radio, which has over 30 programs per week (mostly live), strives to develop alternative models within channels that make sharing of art possible. We are trying to support the beam of a roof that bypasses the market economy and current political obstacles; under which the main concern is the audience who comes together for the music, musician, and art; where no institutional or economical profits exist.
            </p>
            <p>
                Submitted works will go into weekly lists the week following they are review, to run online at www.modyan.com Monday - Sunday 12:00 - 14:00 (GMT +3). We will be knowing and sharing more details during the process, and we thank you for your understanding.
            </p>
            <h5>
                Submission Details:
            </h5>
            <p>
                Deadline: Submissions will be reviewed every Saturday.
            </p>
            <p>
                Length: Between 1 to 15 minutes.
            </p>
            <h5>
                Submission materials:
            </h5>
            <ul>
                <li>Sound file (wav or mp3)</li>
                <li>Info (Title, author, year)</li>
                <li>Statement: 150 words max.</li>
            </ul>
            <p>
                Please send all your materials through Wetransfer as a single folder to radyomodyan@gmail.com.
            </p>
            <p>
                In order to keep this space we are creating on the internet as an open one for everyone to express themselves freely, we advise to keep a constructive attitude and refrain from derogatory tones.
            </p>
            <p>
                By submitting your work, you agree to them being played for a duration of time that will be specified, online at modyan.com by Radyo Modyan, and also them being made available through social media accounts. All rights belong to the artists, always.
            </p>
        </div>-->

        <h4>
            <!-- <span>
                <span v-if="currentLang === 'tr'">
                    WUNDERFEST 8
                </span>
            </span> -->
            <img class="wunder wunder-white" alt="Wunderfest" src="../assets/wunderfest_logo_white.png">
            <img class="wunder wunder-black" alt="Wunderfest" src="../assets/wunderfest_logo_black.png">
        </h4>
        <div v-if="currentLang === 'tr'">
            <p>
                Müziği, müzikseverleri ve doğayı bir araya getirdiğimiz Wunderfest ile Gökçeada’nın yolunu tutuyoruz. 
            </p>
            <p>
                Yıldızların ve gezegenlerin gökyüzünde özgürce var oluşunu kutluyor, sert rüzgarlarda sörf ile denizin elini tutuyor, sıcak güneşten güç alarak doğaya katılıyoruz. Doğanın tarihle buluştuğu bu yerde kendimizi müziğin ruhuna ve kumların titreşimine bırakıyoruz. Farklı müziklerle yıkanıp kumsalda yeni arkadaşlıklar yeşertiyoruz. 
            </p>
            <p>
                Amacımız; rahat ve özgür hissettiğimiz, kaygılarımızı doğanın içinde kaybettiğimiz, sanatçı ve dinleyici olarak yaşama müzikle katılan insanları bir araya getirdiğimiz bir alan açmak. Bu keyfe ortak olmak isteyenlerle o alanda buluşmak, paylaşmak, çoğalmak ve bu deneyimleri, günlük rutinlerimize geri taşıyabilmek…
            </p>
            <p>
                Radyo Modyan ve çevresindeki komünitenin sevgi ve özveriyle düzenlediği Wunderfest, rutine verdiğimiz ufak bir doğa ve müzik arası.
            </p>

            <img class="lineup" alt="Wunderfest" src="../assets/wunder-2022-lineup.png">
        </div>
    </div>
</template>

<script>
    export default {
        name: "MySpotlight",
        data () {
            return {
                currentLang: 'tr'
            }
        },
    }
</script>

<style scoped lang="scss">
    .spotlight {
        // min-height: 500px;
        padding: 10px 30px 100px 40px;

        h4 {
            font-size: 48px;
            margin: 10px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            max-width: 440px;

            .langChooser {
                font-size: 20px;
                display: flex;
                flex-direction: row;
                span {
                    padding: 0 4px;
                    border: 2px solid #2c3e50;
                    margin: 0 4px;
                    cursor: pointer;
                    width: 33px;
                    text-align: center;
                    display: inline-block;
                    &.active {
                        background: #2c3e50;
                        color: white;
                    }
                }
            }
        }
        img.wunder {
            max-width: 450px;
        }
        img.lineup {
            max-width: 600px;
        }
    }

    @media (max-width: 992px) {
        .spotlight {
            padding: 10px 10px 100px 10px;

            h4 {
                font-size: 24px;
                max-width: inherit;

                .langChooser {
                    font-size: 12px;
                }
            }
        }
        img.wunder {
            width: 100%;
            max-width: 450px;
        }
        img.wunder-white {
            display: none;
        }
        img.lineup {
            width: 100%;
            max-width: 600px;
        }
    }

    @media (prefers-color-scheme: dark) {
        .spotlight {
            background: #2d3436;
            color: white;

            img.wunder-white {
                display: inherit;
            }
            img.wunder-black {
                display: none;
            }
        }
    }
</style>
