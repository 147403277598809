<template>
    <div class="player-bar">
        <audio ref="radioPlayer">
            Your browser does not support the
            <code>audio</code> element.
        </audio>
        <div class="audio-controls" ref="radioControls">
            <img v-bind:src="currentArtwork" />
            <marquee-text class="currentTrack" :key="currentTrack" :duration="10" :repeat="10">
                {{currentTrack}}
            </marquee-text>
            <div class="audio-buttons">
                <v-icon v-if="!isPlaying" name="play" scale="2" v-on:click="play()"/>
                <v-icon v-if="isPlaying" name="stop" scale="2" v-on:click="pause()"/>
            </div>
        </div>
    </div>
</template>

<script>
    import * as axios from "axios";
    import MarqueeText from 'vue-marquee-text-component'
    import 'vue-awesome/icons/play'
    import 'vue-awesome/icons/stop'
    import Icon from 'vue-awesome/components/Icon'

    export default {
        name: "MyPlayer",
        components: {
            MarqueeText,
            'v-icon': Icon
        },
        data () {
            return {
                isPlaying: false,
                audioSource: 'http://stream.radio.co/s55d598f42/listen',
                status: {},
                currentTrack: '',
                currentArtwork: ''
            }
        },
        mounted () {
            this.radioCoApi();
            this.intervalFetchData();
            this.isPlaying = this.$refs.radioPlayer.playing;
        },
        methods: {
            radioCoApi: function () {
                axios
                    .get('https://public.radio.co/stations/s55d598f42/status')
                    .then(response => (this.status = response.data))
                    .then(() => {
                        this.currentTrack = this.status.current_track.title;
                        this.currentArtwork = this.status.current_track.artwork_url;
                    })
            },
            intervalFetchData: function () {
                setInterval(() => {
                    this.radioCoApi();
                }, 20000);
            },
            log(item) {
                // eslint-disable-next-line no-console
                console.log(item)
            },
            play() {
                this.isPlaying = true;
                this.$refs.radioPlayer.src = this.audioSource;
                //this.$gtag.event('play', { method: 'start' });
                this.$refs.radioPlayer.volume = 1;
                this.$refs.radioPlayer.play()
            },
            pause() {
                this.isPlaying = false;
                //this.$gtag.event('stop', { method: 'stop' });
                this.$refs.radioPlayer.pause()
                this.$refs.radioPlayer.src = '';
            }
        }
    }


</script>

<style scoped lang="scss">
    $modyanRed: #ed1c24;

    .player-bar {
        height: 90px;
        background-color: #ed1c24;
        bottom: 0;
        left: 0;
        -webkit-box-shadow: 0 1px 30px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0 1px 30px -10px rgba(0,0,0,0.75);
        box-shadow: 0 1px 30px -10px rgba(0,0,0,0.75);
        position: fixed;
        width: 330px;
    }

    .audio-controls {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
            max-width: 64px;
            margin: 0 0 0 10px;
        }

        .marquee-text-text {
            padding: 5px;
        }

        .currentTrack {
            font-size: 18px;
            font-weight: 400;
            color: white;
            margin: 0 10px;
            white-space: nowrap;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .audio-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 20px 0 10px;
        }

        .fa-icon {
            color: #dfe6e9;
            font-size: 72px;
        }
    }

    @media (max-width: 992px) {
        .player-bar {
            height: 60px;
            width: 100%;
        }

        .audio-controls {
            img {
                max-width: 50px;
                margin: 0 0 0 10px;
            }
        }
    }
</style>
